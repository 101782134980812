/**
 * @author panezhang
 * @email panezhang@yangqianguan.com
 * @date 2018-06-01 20:12:27.415
 * @desc generated by yqg-cli@0.1.12
 */

import http from 'ssr-common/vue/vue-http';

export const api = {
    generateCaptcha: '/api/user/generateCaptcha',
    getCaptchaImage: '/api/user/getCaptchaImage',
    sendVerification: '/api/v3/mobile/sendVerification',
    register: '/api/lender/register',
    completeRegisterInfo: '/api/lender/completeRegisterInfo',
    login: '/api/lender/login',
    logout: '/api/lender/logout',
    fetch: '/api/lender/user/fetch',
    fetchBorrowerList: '/api/lender/fetchBorrowerList',
    acceptBorrower: '/api/lender/acceptBorrower',
    denyBorrower: '/api/lender/denyBorrower'
};

export default {
    generateCaptcha() {
        return http.post(api.generateCaptcha, {}); // 必须传一个空对象，不然后端接口会报错
    },

    getCaptchaImageUrl(key) {
        return `${api.getCaptchaImage}/${key}`;
    },

    sendVerification(authData) {
        return http.post(api.sendVerification, authData);
    },

    register(data) {
        return http.post(api.register, data);
    },

    completeRegisterInfo(data) {
        return http.post(api.completeRegisterInfo, data);
    },

    login(data) {
        return http.post(api.login, data);
    },

    logout() {
        return http.post(api.logout, {});
    },

    fetchUserInfo() {
        return http.get(api.fetch);
    },

    fetchBorrowerList() {
        return http.get(api.fetchBorrowerList);
    },

    acceptBorrower(data) {
        return http.post(api.acceptBorrower, data);
    },

    denyBorrower(data) {
        return http.post(api.denyBorrower, data);
    }
};
