/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 11:24:58.449
 * @desc generated by yqg-cli@0.2.4
 */

import Resource from 'moneycan/common/resource';

import ValidOptions from './constant/valid-options';

const TIME_INTERVAL = 60;

export default {
    name: 'SignIn',

    title: 'Pemberi Pinjaman - Masuk',

    data() {
        return {
            KEY: 'EASYCASH_LENDER_AGREEMENT',
            editing: {
                mobileNumber: '',
                verificationCode: ''
            },
            captchaKey: '',
            captcha: '',
            secondCount: 0
        };
    },

    computed: {
        captchaImgUrl() {
            const vm = this;

            return Resource.getCaptchaImageUrl(vm.captchaKey);
        },

        isCounting() {
            const vm = this;

            return vm.secondCount > 0;
        }
    },

    methods: {
        refreshCaptcha() {
            const vm = this;
            Resource.generateCaptcha().then(({body: {body: key}}) => {
                vm.captchaKey = key;
            }).catch(x => x);
        },

        sendVerification() {
            const vm = this;
            const {editing: {mobileNumber}, captcha, captchaKey} = vm;
            if (!captcha || !mobileNumber) {
                vm.$toast.error('Silahkan isi captcha atau nomor telefon');

                return;
            }

            vm.startCountDown();

            const authData = {
                mobileNumber,
                captchaKey,
                captcha,
                verificationPurpose: 'ACTIVE'
            };

            Resource.sendVerification(authData).then(() => {
                vm.$toast.success('Kode verifikasi telah berhasil dikirim');
            }).catch(() => {
                vm.$toast.error('Nomor telefon atau captcha salah');
                vm.refreshCaptcha();
                vm.stopCountDown();
            });
        },

        startCountDown() {
            const vm = this;
            vm.secondCount = TIME_INTERVAL;

            vm.intervalId = setInterval(() => {
                vm.secondCount -= 1;

                if (vm.secondCount <= 0) {
                    vm.stopCountDown();
                }
            }, 1e3);
        },

        stopCountDown() {
            const vm = this;
            vm.secondCount = 0;
            if (vm.intervalId) clearInterval(vm.intervalId);
        },

        login() {
            const vm = this;
            const {editing} = vm;

            if (!vm.$valid.checkOptions(vm.editing, ValidOptions)) return;

            Resource.login(editing).then(() => {
                vm.$toast.success('Login berhasil!');
                vm.$router.replace({name: 'LenderBorrowerList'});
            });
        }
    },

    mounted() {
        this.refreshCaptcha();
    }
};
